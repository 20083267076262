export const isLottery = () => {
  return __SITE__ === 'lottery'
}

export const isVipg = () => {
  return __SITE__ === 'vipg'
}

export const isKm88 = () => {
  return __SITE__ === 'km88'
}
